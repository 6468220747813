document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('form.edit_cfi, form.new_cfi').forEach(function(form) {
    // Two things here.  First, if they choose a medical certification that
    // requires notation, the cfi[medical_certification_note] text area
    // becomes required.
    //
    // Second, if they answer "yes" to any of the "yes/no" questions,
    // cfi[cfis_answered_questions_attributes][0][answer_yes] with a
    // value of "1", then the cfi[yes_answer_explanation] text area
    // becomes required.
    //
    // Of course, every update to those controls needs to set the
    // required attribute, and we need to set it when the form is first
    // displayed.
    //
    // For the medical certifications, the "explanation required" ids
    // are kept in a data field.

    let cfi_medical_certification_selector = form.querySelector('select[name="cfi[medical_certification_id]"]');
    let med_cert_exp_req_ids, medical_certification_note_field;

    function set_medical_certification_note_required() {
      if (medical_certification_note_field) {
        let chosen_medical_cert_id = cfi_medical_certification_selector.value;
        if (med_cert_exp_req_ids.includes(chosen_medical_cert_id)) {
          medical_certification_note_field.required = true;
        } else {
          medical_certification_note_field.required = false;
        }
      } else {
        return false;
      }
    }

    if (cfi_medical_certification_selector) {
      med_cert_exp_req_ids = cfi_medical_certification_selector.dataset.expReqIds.split(/\s*,\s*/)
      medical_certification_note_field = form.querySelector('textarea[name="cfi[medical_certification_note]"]');

      if (medical_certification_note_field) {
        set_medical_certification_note_required();

        cfi_medical_certification_selector.addEventListener("change", set_medical_certification_note_required);
      }
    }

    let questions_fieldset = form.querySelector('fieldset.questions');
    let yes_answer_explanation_field;

    function set_yes_answer_explanation_required() {
      if (yes_answer_explanation_field) {
        if (questions_fieldset.querySelector('input[type=radio][value="1"][name^="cfi[cfis_answered_questions"]:checked')) {
          yes_answer_explanation_field.required = true;
        } else {
          yes_answer_explanation_field.required = false;
        }
      } else {
        return false;
      }
    }

    if (questions_fieldset) {
      yes_answer_explanation_field = form.querySelector('textarea[name="cfi[yes_answer_explanation]"]');
      if (yes_answer_explanation_field) {
        set_yes_answer_explanation_required();

        questions_fieldset.addEventListener("change", set_yes_answer_explanation_required);
      }
    }
  });
});
