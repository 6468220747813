export const make_sortable = function(sortContainer, options={}) {
  sortContainer.addEventListener('dragstart', handleDragStart, false);
  sortContainer.addEventListener('dragover', handleDragOver, false);
  sortContainer.addEventListener('dragend', handleDragEnd, false);

  for (let sortableEl of sortContainer.children) {
    sortableEl.draggable = true;
  }

  var sortableTag = {
    'TABLE': 'TR',
    'TBODY': 'TR',
    'OL': 'LI',
    'UL': 'LI'
  }[sortContainer.nodeName];

  var sourceEl = null;
  var insertedBefore = null;

  function handleDragStart(e) {
    if (e.target.nodeName === sortableTag) {
      sourceEl = e.target;
      e.target.style.opacity = '0.6';
      e.dataTransfer.effectAllowed = 'move';
      if (options.dragStart) options.dragStart(e);
    }
  }

  function handleDragOver(e) {
    if (sourceEl) {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';

      let currentEl = e.target.closest(sortableTag);
      if (currentEl !== sourceEl) {
        let midpoint = currentEl.offsetHeight / 2;
        let willInsertBefore = null;

        if (e.offsetY < midpoint) {
          willInsertBefore = currentEl;
        } else {
          willInsertBefore = currentEl.nextSibling;
        }

        if (willInsertBefore != insertedBefore) {
          sortContainer.insertBefore(sourceEl, willInsertBefore);
          insertedBefore = willInsertBefore;
        }

        if (options.dragOver) options.dragOver(e);
        return false;
      }
    }
  }

  function handleDragEnd(e) {
    if (e.target.nodeName === sortableTag) {
      e.target.style.opacity = '';
      sourceEl = null;
      insertedBefore = null;
      if (options.dragEnd) options.dragEnd(e);
    }
  }
}

export const sortable_serialize = function(elements) {
  let matchData = Array.from(elements).map(el => el.id.match(/^(.+)[-_](.+)$/));

  var formData = new FormData();
  matchData.forEach(function(match) {
    formData.append(`${match[1]}[]`, match[2]);
  });

  return formData;
}
