document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('.cfi-list, .search-results').forEach(function(el) {
    el.addEventListener('click', function(ev) {
      let target = ev.target;
      if (target.nodeName == 'SPAN' && target.parentElement.nodeName == 'A') {
        target = target.parentElement;
      }
      if (target.nodeName == 'A') {
        if (target.classList.contains('bookmark') ||
              target.classList.contains('unbookmark') ||
              target.classList.contains('ignore') ||
              target.classList.contains('unignore')
              ) {
          ev.preventDefault();
          ev.stopPropagation();
          const enclosing_div = target.closest('.utilities');
          const cfi_id = enclosing_div.dataset.cfiId;
          const status = enclosing_div.dataset.status;
          let new_status = null;
          if (target.classList.contains('bookmark') || target.classList.contains('unbookmark')) {
            if (status == 'faved') {
              new_status = '';
            } else {
              new_status = 'faved';
            }
          } else if (target.classList.contains('ignore') || target.classList.contains('unignore')) {
            if (status == 'ignored') {
              new_status = '';
            } else {
              new_status = 'ignored';
            }
          }
          if (new_status !== null) {
            const csrfToken = document.getElementsByTagName('meta')['csrf-token'].content;
            fetch(`/flight_school/cfis/${cfi_id}/update_status.json?status=${new_status}`, {
              method: 'POST',
              credentials: 'same-origin',
              cache: 'no-cache',
              headers: { 'X-CSRF-Token': csrfToken }
            })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              enclosing_div.dataset.status = new_status;
            })
            .catch(function(err) {
              alert("There was an error when trying to update.");
            })
          }
        }
      }
    });
  });
});
