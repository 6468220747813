document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('.home-vanish').forEach(function(el) {
    const offset_top = el.offsetTop;
    const scroll_fade_length = offset_top * 0.85;
    document.addEventListener('scroll', function(e) {
      //console.log(window.pageYOffset);
      let scroll_top = window.pageYOffset;
      if (scroll_top < scroll_fade_length) {
        el.style.opacity = 1.0-(scroll_top/scroll_fade_length);
      } else {
        el.style.opacity = 0.0;
      }
    });
  });
});
