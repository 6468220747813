import { make_sortable, sortable_serialize } from 'make-sortable';

document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('table[data-sortable-table-model]').forEach(function(sortableTable) {
    let pre_path = sortableTable.dataset.sortableTablePath || '/admin';
    let url = pre_path + '/' + sortableTable.dataset.sortableTableModel + '/sort';
    let csrfToken = document.getElementsByTagName('meta')['csrf-token'].content;

    make_sortable(sortableTable.querySelector('tbody'), {
      dragEnd: async function(e) {
        const response = await fetch(url, {
          method: 'POST',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {
            'X-CSRF-Token': csrfToken
          },
          body: sortable_serialize(sortableTable.querySelector('tbody').children)
        });
        if (!response.ok) alert('Your sort failed!');
      }
    });
  });
});
