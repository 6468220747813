document.addEventListener("turbolinks:load", function() {
  // TODO this is not compatible with Internet Explorer
  function stringToElement(htmlString) {
    var template = document.createElement('template');
    template.innerHTML = htmlString;
    return template.content.firstChild;
  }

  function parentNodes(node) {
    if (node === document) return null;

    var parents = [];
    while (node !== document) {
      parents.push(node.parentNode);
      node = node.parentNode;
    }
    return parents;
  }

  document.querySelectorAll('form').forEach(function(form) {
    form.addEventListener('click', function(e) {
      var el = e.target;
      if (el.dataset.action === 'add-to-form') {
        e.preventDefault();
        e.stopPropagation();

        let method = el.dataset.method;
        let content = el.dataset.content;
        var new_id = new Date().getTime();
        var regexp = new RegExp("new_" + method, "g")

        // TODO <tr> id is not changed, e.g. `company_domain_`; is this intended?
        if (content.match(/^\s*<tr\b/)) {
          // Add it to a table
          let new_row = stringToElement(content.replace(regexp, new_id));
          el.closest('fieldset').querySelector('table > tbody').append(new_row);
        } else if (content.match(/^\s*<li\b/)) {
          // Add it to a ul
          let new_item = stringToElement(content.replace(regexp, new_id));
          el.closest('fieldset').querySelector('ul').append(new_item);
        } else {
          let new_element = stringToElement(content.replace(regexp, new_id));
          el.before(new_element);
        }
      }
      if (el.dataset.action === 'remove-from-form') {
        e.preventDefault();
        e.stopPropagation();

        if (el.dataset.confirm) {
          if (!confirm(el.dataset.confirm)) {
            return false;
          }
        }
        el.parentNode.querySelector('input[type=hidden][name*="[_destroy]"]').value = 'true';
        let fields_parents = parentNodes(el).filter(function(parent) {
          if (parent === document) return false;
          return parent.classList.contains('fields');
        });

        if (fields_parents.length > 0) {
          fields_parents[0].style.display = 'none';
          fields_parents[0].querySelectorAll('input, select, textarea').forEach(function(field) {
            field.required = false;
          });
        }
      }
    });
  });
});
